@use '@angular/material' as mat;

@mixin app-message-typography($config) {
  .message-label {
    font: {
      family: mat.font-family($config);
      size: mat.font-size($config, body-2);
      weight: 600;
    }
    line-height: mat.line-height($config, body-2);
  }
  .message-sublabel {
    font: {
      family: mat.font-family($config);
      size: mat.font-size($config, body-1);
    }
  }
}

@mixin app-message-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .app-message {
    &.mat-primary {
      color: mat.get-color-from-palette($primary);
      background-color: mat.get-color-from-palette($primary, 0.15);
    }
    &.mat-accent {
      color: mat.get-color-from-palette($accent);
      background-color: mat.get-color-from-palette($accent, 0.15);
    }
    &.mat-warn {
      color: mat.get-color-from-palette($warn);
      background-color: mat.get-color-from-palette($warn, 0.15);
    }
  }
}
