@use '@angular/material' as mat;

@mixin td-file-theme($theme) {
  $background: map-get($theme, background);

  td-file-upload {
    .td-file-upload-cancel {
      mat-icon {
        background-color: mat.get-color-from-palette($background, background);
      }
    }
  }
  td-file-input {
    /**
    * Class that is added ondragenter by the [TdFileDrop] directive.
    */
    .drop-zone {
      transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
        mat-elevation-transition-property-value();
      @include mat.elevation(8);
    }
  }
}
